<template>
	<div class="layout-top" style="font-family: 'Rajdhani', sans-serif;">

        <div class="layout-sup">
            <button class="p-link layout-logo">
                <img alt="logo" src="mvp_logo.png" height="80">
                <span>Watchlists & Sanctions Screening Software <!--(W&S3Tools)--></span>
                <!--<span>Know Your Customer</span>-->
            </button>


            <div class="layout-top-icons">
                <!--
                <div style="position: absolute; top: 70px; left: 0; color: #2b3b80;" v-if="this.$store.state.authenticated">
                    <button class="p-link movil" @click="onMenuToggle" v-if="this.$store.state.authenticated">
                        <span class="layout-top-icon pi pi-bars"></span> {{$t('Menu')}}
                    </button>
                </div>
                -->
                <button class="p-link movil" style="text-align: center;" @click="enlace('/')" v-if="this.$store.state.authenticated">
                    <span class="layout-top-icon pi pi-home"></span><br>
                    <span class="ocultar">{{$t('Home')}}</span>
                </button>
                <button class="p-link movil" style="text-align: center;" @click="link('https://docs.w3s.mvpsoftware.tech/Glossary')" v-if="this.$store.state.authenticated">
                    <span class="layout-top-icon pi pi-globe"></span><br>
                    <span class="ocultar">{{$t('Glossary')}}</span>
                </button>
                <button class="p-link movil" style="text-align: center;" @click="link('https://docs.w3s.mvpsoftware.tech')" v-if="this.$store.state.authenticated">
                    <span class="layout-top-icon bi bi-book-half"></span><br>
                    <span class="ocultar">{{$t('User manual')}}</span>
                </button>
                <!--
                <button class="p-link movil" style="text-align: center;" @click="enlace('/about')">
                    <span class="layout-top-icon pi pi-globe"></span><br>
                    <span class="ocultar">{{$t('About')}}</span>
                </button>
                <button class="p-link movil" style="text-align: center;" @click="enlace('/personalities')">
                    <span class="layout-top-icon pi pi-slack"></span><br>
                    <span class="ocultar">{{$t('Personalities')}}</span>
                </button>
                <button class="p-link movil" style="text-align: center;" @click="enlace('/test')">
                    <span class="layout-top-icon pi pi-flag"></span><br>
                    <span class="ocultar">{{$t('Take the test')}}</span>
                </button>
                <button class="p-link movil" style="text-align: center;" @click="enlace('/prices')">
                    <span class="layout-top-icon pi pi-shopping-cart"></span><br>
                    <span class="ocultar">{{$t('Prices')}}</span>
                </button>
                -->




                <button class="p-link movil" style="text-align: center;" @click="Logout" v-if="this.$store.state.authenticated">
                    <span class="layout-top-icon pi pi-sign-out"></span><br>
                    <span class="ocultar">{{$t('Exit')}}</span>
                </button>

                <!--
                <button class="p-link movil" style="text-align: center;" @click="openLogin" v-if="!this.$store.state.authenticated">
                    <span class="layout-top-icon pi pi-sign-in"></span><br>
                    <span class="ocultar">{{$t('Login')}}</span>
                </button>
                
                <button class="p-link movil" style="text-align: center;" @click="openRegister" v-if="!this.$store.state.authenticated">
                    <span class="layout-top-icon pi pi-user-plus"></span><br>
                    <span class="ocultar">{{$t('Sign up')}}</span>
                </button>
                
                -->
                <div style="position: absolute; top: 30px; right: 0; color: #2b3b80;">
                    <Dropdown v-model="lenguaje" :options="lenguajes" :optionLabel="i18n.locale()" @change="lenguajeChange" 
                        style="border: unset; border-radius: unset; box-shadow: unset" class="p-mr-5 p-mb-5"/>
                </div>
                
            </div>

 
            <!--
            <div class="layout-top-icons">
                <Menu :menu="menu"/>
            </div>
            -->

        </div>

        <Login :display="displayLogin" @aprob-login="onMenuToggle" @close-login="close"/>
        <Register :display="displayRegister" @aprob-login="onMenuToggle"  @close-register="close"/>
    </div>
</template>

<script>
import { defineAsyncComponent } from "@vue/runtime-core";
import { useI18nPlugin } from '@unify/vuex-i18n';
import router from './routes';
//import Menu from './components/Menu.vue';

export default {
	components: {
        Login: defineAsyncComponent(() => import('./components/Login.vue')),
        Register: defineAsyncComponent(() => import('./components/Register.vue')),
    },
    data() {
        return {
			displayLogin: false,
            displayRegister: false,
            i18n: null,
            origen: [
                {label: 'Home', icon:'pi pi-home', to: '/'},
                {label: 'About', icon:'pi pi-globe', to: '/about'},
                {label: 'Personalities', icon:'pi pi-slack', to: '/services'},
                {label: 'Zelpedia', icon:'pi pi-thumbs-up', to: 'https://zelpedia.zelfium.com/~zelfiumc/'}
            ],
            lenguaje: {en: 'English',   es: 'Inglés',   code:'en'},
            lenguajes: [
                {en: 'English',   es: 'Inglés',     code:'en'},
                {en: 'Spanish',   es: 'Español',    code:'es'},
            ],
            menu: []
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
        this.i18n = useI18nPlugin();
        let leng = 'en';
		if (localStorage.getItem('leng')){
            leng = localStorage.getItem('leng');
        }
        this.$store.state.lenguaje = leng;
        this.i18n.set(leng);
        this.lenguaje = this.lenguajes.filter(valor => valor.code == leng)[0];
        this.lenguajeChange();
        /*
		this.$store.commit('Menu');
        */
    },
    methods: {
        enlace(pag){
            router.push({ path: pag });
        },
        link(url){
			window.open(url, '_blank');
		},
        lenguajeChange() {
            let leng = this.lenguaje.code;
            this.$store.state.lenguaje = leng;
            this.i18n.set(leng);
            localStorage.setItem('leng',leng);
            let nuevo = [];
            this.origen.forEach(element => {
                nuevo.push({
                    label: this.i18n.translate(element.label),
                    icon: element.icon,
                    to: element.to
                });
            });
            this.menu = nuevo;
        },
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
        openLogin() {
            this.$store.commit('Mobile');
            this.displayRegister = false;
            this.$store.state.display.login = true;
            //this.displayLogin = true;
        },
        openRegister() {
            this.$store.commit('Mobile');
            this.displayLogin = false;
            this.$store.state.display.register = true;
            //this.displayRegister = true;
        },
        close() {
            this.displayLogin = false;
            this.displayRegister = false;
            this.limpiar();
        },
        Simulador() {
            this.$router.push({ path: '/simulator' });
        },
        Logout() {
            this.$store.commit('Logout');
            this.onMenuToggle();
        }

		
	}

}
</script>
<style>
.lang-selector {
    
    border: 3px solid #2b3b80;
    
   
    /*
    */
}
</style>